import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image";

const CompanyPage = () => (
    <Layout>
        <SEO title="Yritys" description={'Lisätietoja yrityksestä ja allekirjoittaneesta.'} />
        <div className="company-page">

            <div className="content">
                <div className="columns">
                    <div className="column">
                        <h1>Yritys</h1>
                        <p>Tässä nopea historiaan viittaava esitys yrityksestä polaroid kuvien muodossa. Ne, jotka eivät tiedä mitä polaroid kuvat ovat, niin katsokaa googlesta.</p>
                        <p>Olipa kerran erinomainen kahvila, leipomo ja kauppa nimeltään Uran Baari ja Leipomo. Samoissa tiloissa, joissa ennen toimi Uran Baarin kavila sijaitsee nyt SoftTile.</p>
                        <p>SoftTile Oy aloitti toimintansa näissä tiloissa 2016.</p>
                    </div>
                    <div className="column">
                        <div className="polaroid" style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
                            <Image fluid={'officeOut'} />
                            <div className="container">
                                <p>Kuva ulkoa</p>
                                <p>(Entinen Uran Baari)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="columns">
                    <div className="column">
                        <div className="polaroid second" style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
                            <Image fluid={'office'} />
                            <div className="container">
                                <p>Toimisto</p>
                                <p>#neuvottelupöytä</p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <h1>Toimisto</h1>
                        <p>Entisen kahvilan ilme muutettuna toimistoksi, jossa kaikki magia tapahtuu. Neuvottelupöydän ääreen on hyvä levittäytyä ideoimaan ja toki pitämään palavereitakin.</p>
                        <p>Täällä koodataan kovaa, mutta ei kovakoodata vaan tehdään koodista dynaamisia ja helposti laajennettavia kokonaisuuksia.</p>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="columns">
                    <div className="column">
                        <h1>Jan Johansson</h1>
                        <p>Minulla on 8 vuoden työkokemus teollisuustason sovelluskehityksestä ja projektin vetämisestä, kuten esimerkiksi suuren pörssitasoisen yrityksen ympäristössä.</p>
                        <p>2016 päätin alkaa kokeilla omia siipiäni ja ryhdyin yrittäjäksi, freelanseriksi. Siitä lähtien olen työllistänyt itseni.</p>
                        <p>Kun soitat ruudun alalaidassa olevaan numeroon tai lähetät sähköpostia, niin minä vastaan siihen.</p>
                        <div style={{ width: '50px'}}>
                            <a href={'https://linkedin.com/in/softtilejohansson'} target={'_blank'} rel={'noreferrer'}><Image fluid={'linkedin'} alt={'Linkedin'}/></a>
                        </div>

                    </div>
                    <div className="column">
                        <div className="polaroid" style={{ maxWidth: `350px`, marginBottom: `1.45rem` }}>
                            <Image fluid={'janJoh'} param={'janJoh'}/>
                            <div className="container">
                                <p>Minä itte</p>
                                <p> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </Layout>
)

export default CompanyPage
